import React from "react"
import { 
	graphql, 
	// Link 
} from "gatsby"

// Layout
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { buildFluidImageData } from '@imgix/gatsby'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
// import { faEdit } from '@fortawesome/pro-light-svg-icons'

// Tailwind React UI Components
import { Container, Section } from 'tailwind-react-ui'

// Custom Components
import MediaBlockStacked from "../components/MediaBlockStacked"
import SubPageBanner from '../components/SubPageBanner'
import Pagination from "../components/Elements/Pagination";

// Utilities
import { getPrettyDate } from "../utils/Dates"

// GraphQL
export const query = graphql`
  query BlogQuery($perPage: Int, $skip: Int) {
    resources: allCraftBlogBlogEntry(limit: $perPage, skip: $skip, sort: {order: DESC, fields: postDate}) {
      nodes {
        id
		uri
		url
        slug
		title
		author { fullName }
        postDate
        summary
		remoteId
		typeHandle
		featureImage { ...UploadAssetFragment }
		metaTitle
		metaDescription
		metaKeywords
		metaImage { ...UploadAssetFragment }
		categories {
			title
			uri
		}
      }
    }
	entry: craftOurBlogOurBlogEntry {
		title
		uri
		url
		subtitle
		slug
		featureImage { ...UploadAssetFragment }
	}
	siteInfo: craftSiteInformationGlobalSet {
		...SiteInfoFragment
	}
  }
`

const truncateString = (string = '', maxLength = 54) => 
  string.length > maxLength 
    ? `${string.substring(0, maxLength)}…`
    : string

const OurBlog = ({ data: { entry, resources, siteInfo }, pageContext }) => {
	const subtitle = entry.subtitle || siteInfo.siteTitle
	const [ siteImage ]  = siteInfo.siteImage
	const [ featureImage = siteImage ] = entry.featureImage
	const metaImage = featureImage || entry.metaImage || siteInfo.siteImage
	const metaDescription =  truncateString((entry.metaDescription || entry.summary || siteInfo.siteDescription).replace(/(<([^>]+)>)/gi, ""), 159)
	const metaTitle = truncateString(`${entry.metaTitle || entry.displayTitle || entry.title}`, 53)
	const metaKeywords = entry.metaKeywords || siteInfo.siteKeywords || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300
		}
    )

	return (
		<Layout location={entry.url} title={metaTitle}>
			<Seo
				title       = {metaTitle}
				description = {metaDescription}
				keywords    = {metaKeywords}
				image       = {metaImageFormatted.src}
				url         = {entry.url}
				pathname	= {`/${entry.uri}`}
			/>
			<h1 style={{fontSize: 0, color: 'transparent'}} className="sr-only">{metaTitle}</h1>

			{featureImage && (
				<Section p="0" className="relative">
					<SubPageBanner
						title    = {entry.title}
						subtitle = {subtitle}
						image    = {featureImage}
					/>
				</Section>
			)}

			<Section p="0">
				<Container className="py-14">
					{resources.nodes.map((post, i) => {
						const [ siteImage ] = siteInfo.siteImage
						const [ image = siteImage ] = post.featureImage

					 	return (
							<article key={`article${i}`}>
								<MediaBlockStacked
									author     = {post.author.fullName || 'Table Mountain Aerial Cableway'}
									categories = {post.categories}
									date       = {getPrettyDate(post.postDate)}
									image      = {image}
									linkText   = "Read more"
									text       = {post.summary}
									title      = {post.title}
									uri        = {post.uri}
								/>
							</article>
						)
					})}
				</Container>
			</Section>

			{pageContext.pagination.lastPage > 1 && (
				<div className="bg-gray-light w-full text-center justify-center align-center">
					<Pagination className="p-3" pagination={pageContext.pagination} />
				</div>
			)}

		</Layout>
	)
}

export default OurBlog