import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

class UrlWindow {
  /**
   * Create a new URL window instance.
   */
  constructor(pagination) {
    this.pagination = pagination;
  }

  /**
   * Get the window of URLs to be shown.
   */
  get() {
    let $onEachSide = this.pagination.onEachSide;

    if (this.pagination.lastPage < $onEachSide * 2 + 8) {
      return this.getSmallSlider();
    }

    return this.getUrlSlider($onEachSide);
  }

  /**
   * Get the slider of URLs there are not enough pages to slide.
   */
  getSmallSlider() {
    return {
      first: this.getUrlRange(1, this.pagination.lastPage),
      slider: null,
      last: null,
    };
  }

  /**
   * Create a URL slider links.
   */
  getUrlSlider($onEachSide) {
    let $window = $onEachSide + 4;

    if (!this.hasPages()) {
      return { first: null, slider: null, last: null };
    }

    if (this.pagination.lastPage < $onEachSide * 2 + 8) {
      return this.getSmallSlider();
    }

    // If the current page is very close to the beginning of the page range, we will
    // just render the beginning of the page range, followed by the last 2 of the
    // links in this list, since we will not have room to create a full slider.
    if (this.pagination.currentPage <= $window) {
      return this.getSliderTooCloseToBeginning($window, $onEachSide);
    }

    // If the current page is close to the ending of the page range we will just get
    // this first couple pages, followed by a larger window of these ending pages
    // since we're too close to the end of the list to create a full on slider.
    else if (this.pagination.currentPage > this.pagination.lastPage - $window) {
      return this.getSliderTooCloseToEnding($window, $onEachSide);
    }

    // If we have enough room on both sides of the current page to build a slider we
    // will surround it with both the beginning and ending caps, with this window
    // of pages in the middle providing a Google style sliding pagination setup.
    return this.getFullSlider($onEachSide);
  }

  /**
   * Get the slider of URLs when too close to beginning of window.
   */
  getSliderTooCloseToBeginning($window, $onEachSide) {
    return {
      first: this.getUrlRange(1, $window + $onEachSide),
      slider: null,
      last: this.getFinish(),
    };
  }

  /**
   * Get the slider of URLs when too close to ending of window.
   */
  getSliderTooCloseToEnding($window, $onEachSide) {
    let $last = this.getUrlRange(
      this.pagination.lastPage - ($window + ($onEachSide - 1)),
      this.pagination.lastPage
    );

    return {
      first: this.getStart(),
      slider: null,
      last: $last,
    };
  }

  /**
   * Get the slider of URLs when a full slider can be made.
   */
  getFullSlider($onEachSide) {
    return {
      first: this.getStart(),
      slider: this.getAdjacentUrlRange($onEachSide),
      last: this.getFinish(),
    };
  }

  /**
   * Get the page range for the current page window.
   */
  getAdjacentUrlRange($onEachSide) {
    return this.getUrlRange(
      this.pagination.currentPage - $onEachSide,
      this.pagination.currentPage + $onEachSide
    );
  }

  /**
   * Get the starting URLs of a pagination slider.
   */
  getStart() {
    return this.getUrlRange(1, 2);
  }

  /**
   * Get the ending URLs of a pagination slider.
   */
  getFinish() {
    return this.getUrlRange(
      this.pagination.lastPage - 1,
      this.pagination.lastPage
    );
  }

  /**
   * Determine if the underlying pagination being presented has pages to show.
   */
  hasPages() {
    return this.pagination.lastPage > 1;
  }

  /**
   * Get the current page from the pagination.
   */
  currentPage() {
    return this.pagination.currentPage;
  }

  /**
   * Get the last page from the pagination.
   */
  lastPage() {
    return this.pagination.lastPage;
  }

  getUrlRange(from, to) {
    const range = {};
    for (let i = from; i <= to; i++) {
      range[i] =
        i === 1 ? `${this.pagination.base}` : `${this.pagination.base}/p${i}`;
    }

    return range;
  }

  elements() {
    const $window = this.getUrlSlider(1);

    const arr = [
      $window["first"],
      $window["slider"] ? "..." : null,
      $window["slider"],
      $window["last"] ? "..." : null,
      $window["last"],
    ].filter(Boolean);

    return arr;
  }
}

const Pagination = ({ pagination, className }) => {
  return (
    <div className={className}>
      <div className="block sm:hidden">
        <PaginationSimple pagination={pagination} />
      </div>
      <div className="hidden sm:block">
        <PaginationFull pagination={pagination} />
      </div>
    </div>
  );
};

const PaginationFull = ({ pagination, showing = false }) => {
  const urlWindow = new UrlWindow(pagination);

  return (
    <div>
      <nav
        role="navigation"
        aria-label="Pagination Navigation"
        className="flex items-center justify-between pagination"
      >
        <div className="hidden sm:flex-1 sm:flex flex-col sm:items-center sm:justify-between">
          {showing && (
            <div className="mb-2">
              <p className="text-gray-700">
                Showing <span>{pagination.firstItem}</span> to{" "}
                <span>{pagination.lastItem}</span> of{" "}
                <span>{pagination.total}</span> results
              </p>
            </div>
          )}

          <div className="rounded-full text-sm text-primary border overflow-hidden shadow-sm bg-white">
            <span className="relative z-0 inline-flex rounded-0">
              {pagination.currentPage === 1 && (
                <span aria-disabled="true" aria-label="Previous">
                  <span
                    className="uppercase relative inline-flex items-center px-3 py-2 bg-white border-t-0 border-b-0 border-l border-gray-200 border-r-0 cursor-default rounded-0 leading-7 h-full active:bg-primary active:text-white focus:bg-primary focus:text-white hover:bg-gray-200"
                    aria-hidden="true"
                  >
                    <FontAwesomeIcon
                      size="lg"
                      className={`mb-1`}
                      icon={faChevronLeft}
                      alt="Previous icon"
                      aria-label="Go to previous page"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;Previous
                  </span>
                </span>
              )}

              {pagination.currentPage !== 1 && (
                <Link
                  to={pagination.previousPageUrl}
                  rel="prev"
                  className="uppercase relative inline-flex items-center px-3 py-2 bg-white border-t-0 border-b-0 border-l border-gray-200 border-r-0 rounded-0 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 transition ease-in-out duration-150 leading-7 h-full active:bg-primary active:text-white focus:bg-primary focus:text-white hover:bg-gray-200"
                  aria-label="Previous"
                >
                  <FontAwesomeIcon
                    size="lg"
                    className={`mb-1`}
                    icon={faChevronLeft}
                    alt="Previous icon"
                    aria-label="Go to previous page"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;Previous
                </Link>
              )}

              {/* -- "Three Dots" Separator -- */}
              {urlWindow.elements().map((element, i) => {
                if (typeof element === "string") {
                  return (
                    <span key={i} aria-disabled="true">
                      <span className="relative inline-flex items-center px-4 py-2  border-t-0 border-b-0 border-l border-gray-200 border-r-0 cursor-default leading-7 h-full">
                        {element}
                      </span>
                    </span>
                  );
                } else {
                  if (typeof element === "object") {
                    return Object.keys(element).map((page) => {
                      if (page === pagination.currentPage) {
                        return (
                          <span key={page} aria-current="page">
                            <span className="relative inline-flex items-center px-4 py-2 bg-primary text-white border border-gray-200 border-r-0 cursor-default leading-7 h-full active:bg-primary active:text-white focus:bg-primary focus:text-white hover:bg-gray-200">
                              {page}
                            </span>
                          </span>
                        );
                      } else {
                        return (
                          <Link
                            key={page}
                            to={element[page]}
                            className="relative inline-flex items-center px-4 py-2  border-t-0 border-b-0 border-l border-gray-200 border-r-0 hover:text-gray-500 transition ease-in-out duration-150 leading-7 h-full active:bg-primary active:text-white focus:bg-primary focus:text-white hover:bg-gray-200"
                            aria-label={`Go to page ${page}`}
                          >
                            {page}
                          </Link>
                        );
                      }
                    });
                  }
                }
                return <></>;
              })}

              {/*{{-- Next Page Link --}}*/}
              {pagination.lastPage > pagination.currentPage && (
                <Link
                  to={pagination.nextPageUrl}
                  rel="next"
                  className="uppercase relative inline-flex items-center px-3 py-2 bg-white border border-t-0 border-b-0 border-gray-200 border-r-0 rounded-0 transition ease-in-out duration-150 leading-7 h-full"
                  aria-label="Next"
                >
                  Next&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon
                    size="lg"
                    className={``}
                    icon={faChevronRight}
                    alt="Next icon"
                    aria-label="Go to next page"
                  />
                </Link>
              )}
              {pagination.lastPage === pagination.currentPage && (
                <span
                  aria-disabled="true"
                  aria-label="Next"
                  aria-current="page"
                >
                  <span
                    className="uppercase relative inline-flex items-center px-3 py-2 bg-primary text-white border border-t-0 border-b-0 border-gray-200 border-r-0 cursor-default rounded-0 leading-7 h-full"
                    aria-hidden="true"
                  >
                    Next&nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon
                      size="lg"
                      className={``}
                      icon={faChevronRight}
                      alt="Next icon"
                      aria-label="Go to next page"
                    />
                  </span>
                </span>
              )}
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;

export const PaginationSimple = ({ pagination }) => {
  return (
    <div>
      <nav
        role="navigation"
        aria-label="Pagination Navigation"
        className="flex items-center justify-center"
      >
        <div className="">
          {pagination.currentPage === 1 && (
            <span className="relative inline-flex items-center px-4 py-2 bg-white border border-gray-200 border-r-0 cursor-default rounded-0">
              &laquo; Previous
            </span>
          )}

          {pagination.currentPage !== 1 && (
            <Link
              to={pagination.previousPageUrl}
              className="relative inline-flex items-center px-4 py-2  border border-gray-200 border-r-0 rounded-0 hover:text-gray-500 transition ease-in-out duration-150"
            >
              &laquo; Previous
            </Link>
          )}

          {pagination.lastPage > pagination.currentPage && (
            <Link
              to={pagination.nextPageUrl}
              className="relative inline-flex items-center px-4 py-2 ml-3  border border-gray-200 border-r-0 rounded-0 hover:text-gray-500 transition ease-in-out duration-150"
            >
              Next &raquo;
            </Link>
          )}

          {pagination.lastPage === pagination.currentPage && (
            <span className="relative inline-flex items-center px-4 py-2 ml-3 bg-white border border-gray-200 border-r-0 cursor-default rounded-0">
              Next &raquo;
            </span>
          )}
        </div>
      </nav>
    </div>
  );
};
