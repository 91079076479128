import React from 'react'
import ImgixFluidImage from "./ImgixFluidImage"

// Tailwind React UI
import { 
	Box,
	Card,
	CardBody,
	FillButton,
	Flex,
	Text,
	// Title
} from 'tailwind-react-ui'

function truncateString(str, num) { 
	if (str.length <= num) { return str } return str.slice(0, num) + '...' 
} 

const MediaBlockStacked = (props) => {
	const image = props.image
	return (
		<Card className="flex flex-wrap items-stretch justify-center mx-auto my-10 lg:w-5/6 xl:w-4/5" data-component-type="media-block-stacked">
			<Box className="w-full md:w-1/3 overflow-hidden">
				<ImgixFluidImage className="w-full bg-gray-light relative block md:hidden rounded-lg mt-1 mb-5" title={props.title} alt={image.imageAlt ?? image.title ?? image.filename} imageUrl={image.url} ar={3} focalPoint={image.focalPoint} sizes={`(min-width: 700) 520px, 720px`} /> 
				<ImgixFluidImage className="w-full bg-gray-light relative rounded-lg mt-1 hidden md:block" title={props.title} alt={image.imageAlt ?? image.title ?? image.filename} imageUrl={image.url} ar={1} focalPoint={image.focalPoint} sizes={`(min-width: 700) 520px, 720px`} /> 
			</Box>
			<CardBody className="w-full md:w-2/3 px-5 lg:pl-10 flex flex-col pt-0 pb-2 overflow-hidden">
				<Box className="flex-grow">
					<Text is="p" className="mb-2 text-xs" text="gray-dark">{props.date}</Text>
					<h3 className="font-heading leading-tight text-primary text-2xl md:text-3xl"><a href={'/' + props.uri} className="hover:text-green transition-all transition-3">{props.title}</a></h3>
					{props.text ?<span text="primary">{truncateString(props.text.replace(/<[^>]+>/g, ''), 152)}</span> : ''}
				</Box>
				<Flex wrap items="baseline" className="pt-5 mt-auto">
					<Box>
						<FillButton font="display" data-type="button" bg="white" text="primary" text-hocus="white" is="a" href={'/' + props.uri} className="btn border-primary focus:border-primary px-3 lg:px-8 py-3 rounded-full whitespace-nowrap mr-3 my-0 focus:bg-primary">
							<span className="block relative">{props.linkText ?? "Read more"}</span>
						</FillButton>
					</Box>
					{props.categories ? 
					<Box>
						{props.categories.map((category, i) => (
							<FillButton key={i} font="display" data-type="button" bg="gray-light" text="gray-500" is="a" href={'/' + category.uri} leading="none" className="btn border text-xs px-2 py-0 rounded-full whitespace-nowrap mr-2">
								<small className="block relative">{category.title}</small>
							</FillButton>
						))}
					</Box> : null }
				</Flex>
			</CardBody>
		</Card>
	)
}

export default MediaBlockStacked